.ourNavbar {
	background-color: #EEEEEE;
	box-shadow: 5px 0 5px #C0C0C0;
	/*margin-left: 250px;*/
}

.nav-item {
	display: flex;
	flex-wrap: nowrap;
}

.name {
	font-size: 1.2em;
}

.profile-photo-frame {
	border-radius: 50%;
	border: 2px solid #5A6268;
	margin-left: 5px;
	overflow: hidden;
}

.icon::before {
	/*margin-right: 5px;*/
	/*display: inline;*/
	/*font-family: 'Font Awesome 5 Free','PT Sans', sans-serif;*/
}

.dropdownHeader {
	font-size: 0.95em;
}

#profile-photo {
	width: 40px;
	height: 40px;
}
