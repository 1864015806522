#sidebar-wrapper {
  background-color: #03C1AF;
  box-shadow: 3px 5px 5px #D5D5D5;
  color: #FFFFFF;
  height: 100vh;
  /*margin-left: -15rem;*/
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  /*position: fixed;*/
  /*width: 33%;*/
  /*max-width: 300px;*/
  /*overflow-y: auto;*/
}

#sidebar-wrapper .sidebar-heading {
  background-color: #03C1AF;
  font-size: 1.2rem;
}

#sidebar-wrapper .sidebar-sub-heading {
  background-color: rgba(0, 0, 0, 0.1);
  /*margin-top: 45px;*/
  font-size: 17px;
  font-weight: bold;
}

.scribo-logo {
  width: 70px;
  height: 70px;
}

.scribo-brand {
  height: 32px;
  width: 108px;
}
