.patient-info {
	background-color: #FFFFFF;
	border: 2px solid #03C1AF;
	/*min-width: 500px;*/
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	color: #707070;
  }

.patient-photo-frame {
	border-radius: 50%;
	border: 2px solid #5A6268;
	overflow: hidden;
	margin-right: 5px;
}

#patient-photo {
	width: 50px;
	height: 50px;
}

.patient-name {
	font-size: 20px;
	margin-left: 5px;
	margin-right: 5px;
}

.largeSize {
	font-size: 1.35em;
}
