#sidebar-wrapper .list-group .list-group-item {
    background-color: #03C1AF;
    border-bottom: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 16px;
}

#sidebar-wrapper .list-group-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.noOutline {
    padding: 0.75em 0.5em;
}

.noOutline:focus {
    outline: 0;
    background-color: rgba(3, 193, 175, 0.1);
}

.container-non-selected-item {
    color: white !important;
}

.container-selected-item {
    background-color: #DDDDDD !important;
    border-color: white !important;
    border-width: 2px !important;
    font-size: 1.15em !important;
    color: #03C1AF !important;
}

.selectedItem {
    background-color: #DDDDDD !important;
    font-size: 1.10em !important;
    color: #03C1AF !important;
}

.prescription-name {
    font-size: 20px;
    font-family: made_future_x_light;
    color: white;
}

.prescription-details {
    font-size: 1em;
    font-family: made_future_x_light;
    color: white;
}

.prescription-date {
    font-size: 20px;
    font-family: made_future_x_light;
    color: white;
}

