.card {
	box-shadow: 5px 5px 5px #D5D5D5;
	border-radius: 5px;
}

.card > .card-header, .card-body {
	background-color: #FFFFFF;
}

.card > .card-header {
	font-size: 1.25em;
	font-weight: bold;
}

.card > .card-body {
	font-size: 1.05em;
}

.sign-text {
	color: #03C1AF;
	font-size: 18px;
	font-weight: bold;
}

.expand-card {
	color: #03C1AF;
}

.itemsLabel {
	font-size: 1.15em;
}

.sign-button {
	/*background-color: rgb(55, 198, 60);*/
	/*box-shadow: 0 5px 5px 0 #D5D5D5;*/
	color: #FFFFFF;
	border-radius: 23px;
	width: 131px;
	height: 51px;
	font-size: 23px;
	font-weight: bold;
	outline: none;
}

.sign-button:hover {
	cursor: pointer;
}

.table {
	width: 80%;
}

.table tbody tr td {
	border-bottom: 1px solid #D3D3D3;
}

.emailLabel {
	margin-right: 5px;
}

.email, .readOnlyEmail {
	font-size: 1.25em;
	padding-left: 18px;
	height: calc(2em + 1rem + 2px) !important;
}

.email:focus {
	border-color: #ced4da;
	box-shadow: none;
}

.readOnlyEmail, .readOnlyEmail:focus {
	border-color: #ced4da;
	box-shadow: none;
}

.emailAddButton {
	font-size: 1.5em;
}

.progressBar {
	height: 30px;
}

.progressText {
	font-size: 1.25em;
	font-weight: bold;
}

.secondaryProgressText {
	font-size: 1.05em;
}

.errorText {
	color: red;
}

.oneThird {
	width: 33.3%;
}

.buttonAddRemove {
	font-size: 1.3em;
}
